import React from 'react';
import { BuyButton } from './BuyButton';
import { ProfitLoss } from './ProfitLoss';
import { TradePanelForm } from './TradePanelForm';

// 合约 交易面板
export const TradePanel: React.FC = () => {
  return (
    <div className="px-4 mt-1">
      {/* 下单数量输入框主体 */}
      <TradePanelForm />

      {/* 止盈/止损 组件 */}
      <ProfitLoss />

      {/* 下单 组件 按钮 */}
      <BuyButton />
    </div>
  );
};
