import type { SVGProps } from 'react';

export function TransferIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.2008 8.5006C2.16168 8.50366 2.12411 8.51724 2.09207 8.53988C2.06003 8.56253 2.03471 8.59342 2.01877 8.62927C2.00283 8.66513 1.99688 8.70462 2.00154 8.74358C2.0062 8.78254 2.0213 8.81952 2.04524 8.85061L6.12858 12.4362C6.27635 12.5295 6.47858 12.4828 6.47858 12.2806V10.0095H12.4386C12.5048 10.0128 12.571 10.0023 12.633 9.97843C12.6949 9.95459 12.7511 9.91804 12.798 9.87114C12.8449 9.82423 12.8815 9.768 12.9053 9.7061C12.9291 9.64419 12.9397 9.57797 12.9364 9.51172V8.99838C12.9397 8.93213 12.9291 8.86591 12.9053 8.804C12.8815 8.74209 12.8449 8.68587 12.798 8.63896C12.7511 8.59205 12.6949 8.5555 12.633 8.53167C12.571 8.50784 12.5048 8.49726 12.4386 8.5006H2.2008Z"
        fill="hsl(var(--nextui-primary)" />
      <path
        d="M12.8773 6.44238C12.8452 6.46503 12.8077 6.4786 12.7686 6.48166H2.49966C2.43341 6.48501 2.36719 6.47442 2.30528 6.45059C2.24337 6.42676 2.18715 6.39021 2.14024 6.3433C2.09333 6.2964 2.05678 6.24017 2.03295 6.17826C2.00912 6.11636 1.99854 6.05014 2.00188 5.98388V5.48611C1.9956 5.41576 2.00509 5.3449 2.02968 5.27869C2.05427 5.21248 2.09333 5.1526 2.14401 5.10341C2.19469 5.05422 2.25571 5.01697 2.32262 4.99437C2.38953 4.97176 2.46065 4.96439 2.53077 4.97277H8.49078V2.70166C8.49078 2.49944 8.693 2.45277 8.84077 2.54611L12.9241 6.13166C12.9481 6.16275 12.9632 6.19972 12.9678 6.23868C12.9725 6.27764 12.9665 6.31714 12.9506 6.35299C12.9346 6.38885 12.9093 6.41973 12.8773 6.44238Z"
        fill="hsl(var(--nextui-primary)" />
    </svg>
  );
}
