//交易类型
export enum ContractTypeEnum {
  //市价
  MARKET = 1,
  //限价
  LIMIT = 2,
}

//交易方向
export enum TradingDirectionEnum {
  //买涨
  LONG = 1,
  //买跌
  SHORT = 2,
}

export enum MarginModeEnum {
  // 全仓模式
  ALL = 1,
  // 逐仓模式
  ISOLATED = 2,
}
