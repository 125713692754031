import { MdiRhombus } from '@/assets/font/MdiRhombus';
import ThemeEnum from '@/enums/themeEnum';
import { cn } from '@nextui-org/react';
import { useDebounceFn, useUpdateEffect } from 'ahooks';
import { useTheme } from 'next-themes';
import React, { TouchEvent, useRef, useState } from 'react';

interface SliderProps {
  values: any[];
  value: number;
  onChange: (value: number) => void;
  unit?: string;
  classNames?:{
    base: string;
  }
}

const Slider: React.FC<SliderProps> = ({
  values,
  value,
  onChange,
  classNames,
  unit = '',
}) => {
  // 当前滚动条
  const slider = useRef<HTMLDivElement>(null);
  // ---------------------------组件使用逻辑，双向绑定------------------------------------------------
  // 记录当前位置-索引
  const [currentPosition, setCurrentPosition] = useState(
    values.findIndex((item) => +item === +value) !== -1
      ? values.findIndex((item) => +item === +value)
      : 0,
  );

  // 双向绑定外部传入的值
  useUpdateEffect(() => {
    setCurrentPosition(values.findIndex((item) => +item === +value));
  }, [value]);

  useUpdateEffect(() => {
    onChange(values[currentPosition]);
  }, [currentPosition]);

  // ---------------------------移动逻辑------------------------------------------------
  // 计算节点的位置
  const getNodePosition = (index: number) => {
    if (index === 0) return 2;
    if (index === values.length - 1) return 98;
    // return values.at(index) / (values.at(-1) / 100) + 2;
    // 根据个数和当前索引计算百分比
    return (index / (values.length - 1)) * 100;
  };
  // 鼠标落下坐标
  const [startX, setStartX] = useState(0);

  //鼠标落下事件
  const handleTouchStart = (event: TouchEvent) => {
    setStartX(event.touches[0].clientX);
  };
  //鼠标移动事件
  const { run: handleTouchMove } = useDebounceFn(
    (event: TouchEvent) => {
      // 计算当前移动距离
      const moveDistance = event.touches[0].clientX - startX;

      // 判断是否需要后续操作，第一个节点和最后一个节点就不需要后续操作
      if (!slider.current) return;
      if (moveDistance > 0 && currentPosition > values.length - 1) return;
      if (moveDistance < 0 && currentPosition === 0) return;

      // 这里纯数学题，很抽象，不会直接不看

      // 向右移动
      if (moveDistance > 0 && currentPosition < values.length - 1) {
        // 向右边滑动判断是否超过当前节点和下一个节点的距离的一半;再加上已经移动的距离
        const movingRangeDistance =
          (((getNodePosition(currentPosition + 1) -
            getNodePosition(currentPosition)) /
            100) *
            slider.current.offsetWidth) /
          2;

        if (moveDistance > movingRangeDistance) {
          setStartX(startX + slider.current.offsetWidth / (values.length - 1));
          setCurrentPosition(currentPosition + 1);
        }
      }

      // 向左移动
      if (moveDistance < 0 && currentPosition > 0) {
        // 向右边滑动判断是否超过当前节点和下一个节点的距离的一半;再加上未移动的地方的距离
        const movingRangeDistance =
          (((getNodePosition(currentPosition) -
            getNodePosition(currentPosition - 1)) /
            100) *
            slider.current.offsetWidth) /
          2;
        if (Math.abs(moveDistance) > movingRangeDistance) {
          setStartX(startX - slider.current.offsetWidth / (values.length - 1));

          setCurrentPosition(currentPosition - 1);
        }
      }
    },
    {
      wait: 5,
    },
  );

  //鼠标放开事件
  const handleTouchEnd = async () => {
    setStartX(0);
  };

  //点击进度条
  const handleClickSlider = (event: any) => {
    // 计算当前点的位置然后切换至最近的节点
    if (slider.current) {
      const sliderWidth = slider.current.offsetWidth;
      const clickPosition =
        event.clientX - slider.current.getBoundingClientRect().left;
      const clickPercentage = clickPosition / sliderWidth;
      const nearestIndex = Math.round(clickPercentage * (values.length - 1));
      setCurrentPosition(nearestIndex);
    }
  };

  const { theme: nextUiTheme }: any = useTheme();

  // console.log(value, 'values');
  // console.log(currentPosition, 'currentPosition');

  return (
    <div>
      <div
        ref={slider}
        onClick={handleClickSlider}
        // className={cn('relative h-1 select-none rounded-md bg-backgroundAuxiliaryColor w-full my-2 ',classNames?.base)}
        className={cn('relative h-1 select-none rounded-md bg-backgroundAuxiliaryColor w-full my-2 ',classNames?.base)}
      >
        {values.map((val, index) => (
          <div key={val}>
            {/*当前 节点，绑定拖拽事件 菱形小图标  */}
            <MdiRhombus
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              className={cn(
                `absolute top-1/2  h-[13px] w-[13px] z-20 text-[backContrastColor]  transform -translate-x-1/2 -translate-y-1/2 `,
                {
                  '!text-backContrastColor  h-[15px] w-[15px]':
                    index === currentPosition,
                  '!text-[#666] h-[11px] w-[11px]': Number(val) > Number(value),
                },
              )}
              style={{
                left: `${getNodePosition(index)}%`,
                fill: Number(val) > Number(value) ? 'transparent' : '',
              }}
            />
          </div>
        ))}

        {/* // 已经选中的 进度 背景 */}
        <div
          className={cn(
            `absolute top-0 h-full  rounded-md bg-backContrastColor`,
            {
              hidden: currentPosition === 0,
            },
          )}
          style={{ width: `${getNodePosition(currentPosition)}%` }}
        />
      </div>

      {/* 下面是数字 */}
      <div className="relative mt-5 select-none">
        {values.map((val, index) => (
          <div key={val}>
            <div
              className={cn(
                `absolute text-auxiliaryTextColor text-center z-40 transform -translate-y-1/2  -translate-x-1/2 `,
                {
                  '-translate-x-0': index === 0,
                },
              )}
              style={{ left: `${getNodePosition(index)}%` }}
            >
              {val}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
