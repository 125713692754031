import ValueDisplay from '@/components/display/displayValueDisplay';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContractTradeModel } from '../contractModel';
import { ContractTypeEnum } from '../type';
import { truncateNumber } from '@/utils/number';

/**
 * 买卖价格波动组件 ，深度图
 */
export function OrderBook() {
  const { localContract } = useModel('contract');

  const modelProps = useContractTradeModel() || {};
  const { hasTlTp, setPrice: onPriceChange, type } = modelProps || {};

  // 返回深度图显示的行数
  const orderBookRowNum = useMemo(() => {
    if (hasTlTp) {
      // 有止盈的情况
      if (type === ContractTypeEnum.LIMIT) {
        // 止盈限价
        return 11;
      }
      // 止盈 市价
      return 9;
    }

    // 没有止盈的情况
    if (type === ContractTypeEnum.LIMIT) {
      // 无止盈限价
      return 7;
    }
    // 无止盈 市价
    return 6;
  }, [hasTlTp, type]);

  const { getSocketDepthByName, getSocketRowByName, sendMsg, isSub }: any =
    useModel('socket');
  const socketData = getSocketRowByName(localContract?.symbol);
  const depth = getSocketDepthByName(localContract?.symbol);

  const { t } = useTranslation();

  const pageKey = usePageRefreshOnVisible();
  const { readyState } = useModel('socket');

  useEffect(() => {
    if (localContract?.symbol && isSub) {
      sendMsg(
        `{"type":"SUB_DEPTH","data": ${JSON.stringify(localContract?.symbol)}}`,
      );
    }
  }, [localContract, isSub, pageKey, readyState]);

  const priceAccuracy = useMemo(
    () => localContract?.priceAccuracy ?? 3,
    [localContract?.priceAccuracy],
  );

  // 数据预处理，使用 useMemo 避免每次重新计算
  const {
    reassembledBuyArr,
    reassembledSellArr,
    maxAsks,
    minAsks,
    maxBids,
    minBids,
  } = useMemo(() => {
    const getMinMax = (arr: any[], key: number) => {
      const values = arr.map((item) => item?.[key] || 0);
      return {
        max: Math.max(...values),
        min: Math.min(...values),
      };
    };

    const sortAndReassemble = (arr: any[], isSell: boolean) => {
      // 按 p 降序排序
      const sortedArr = [...arr]?.sort((a, b) => b.p - a.p)?.map?.(item => {
        return {
          ...item,
          p: truncateNumber(item.p, priceAccuracy),
        };
      });

      // 用 Map 记录去重后的数据 (键为 p，值为 n)
      const uniqueMap = new Map();
      sortedArr.forEach((item) => {
        if (!uniqueMap.has(item.p)) {
          uniqueMap.set(item.p, item.n);
        }
      });

      // 生成去重后的数组
      const deduplicatedArr = Array.from(uniqueMap, ([p, n]) => ({ p, n }));

      // 提取去重后的 n 值数组
      const sortedPrices = deduplicatedArr.map((item) => item.n);

      // 组合返回
      return deduplicatedArr.map((item, index) => [item.p, sortedPrices[index]]);
    };

    const buyArr = sortAndReassemble(depth?.a || [], false);
    const sellArr = sortAndReassemble(depth?.b || [], true);
    const { max: maxAsks, min: minAsks } = getMinMax(buyArr, 0);
    const { max: maxBids, min: minBids } = getMinMax(sellArr, 0);

    return {
      reassembledBuyArr: buyArr,
      reassembledSellArr: sellArr,
      maxAsks,
      minAsks,
      maxBids,
      minBids,
    };
  }, [depth]);

  const renderOrderRow = (
    data: any[],
    isSell: boolean,
    max: number,
    min: number,
  ) => {
    return data?.slice(0, orderBookRowNum)?.map((item, index) => {
      const gradientLength = ((item?.[0] - min) / (max - min)) * 100;
      const backgroundColor = isSell
        ? 'rgba(144,238,144,0.05)'
        : 'rgba(255,0,0,0.05)';
      const propsColor = isSell ? '#2db880' : '#f23645';
      return (
        <div
          key={index}
          style={{
            position: 'relative',
            overflow: 'hidden',
          }}
          className=""
          onClick={() => {
            onPriceChange(RenderUtil.formatNum(item?.[0], priceAccuracy));
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: `${gradientLength}%`,
              background: backgroundColor,
            }}
          />
          <div className="flex justify-between mb-[8px]">
            <ValueDisplay
              propsColor={propsColor}
              className="text-[11px] font-medium"
              value={item?.[0]}
              len={priceAccuracy}
              justify="justify-start"
              fontSize={13}
              isSubLen={false}
            />
            <div className=" flex justify-center items-center text-[11px]">
              {RenderUtil.shortenNumber(RenderUtil.formatNum(item?.[1], 0))}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="h-full flex flex-col pt-1">
      <div className="flex justify-between text-auxiliaryTextColor text-[11px] font-normal">
        <div>{t('价格')}</div>
        <div>{t('数量')}</div>
      </div>
      <div className="flex justify-between text-auxiliaryTextColor text-[11px] pb-[4px] ">
        <div>(USDT)</div>
        <div>({t('张')})</div>
      </div>

      <div className="flex flex-1 flex-col">
        <div>{renderOrderRow(reassembledBuyArr, false, maxAsks, minAsks)}</div>

        <div className="text-xs py-1 pb-2">
          <div className="flex justify-start items-center">
            <ValueDisplay
              className="text-base flex-1 font-bold"
              value={socketData.a}
              len={priceAccuracy}
              isSubLen={false}
              fontSize={16}
              justify="justify-start"
            />
          </div>
        </div>

        <div>{renderOrderRow(reassembledSellArr, true, maxBids, minBids)}</div>
      </div>
    </div>
  );
}
