import { AntDesignDownOutlined } from '@/assets/icons/comm/AntDesignDownOutlined';
import BaseInputNumber from '@/components/base/baseInputNumber';
import { Accordion, AccordionItem, Checkbox, cn } from '@nextui-org/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContractTradeModel } from '../contractModel';

/* 合约 止盈/止损 组件 */
export const ProfitLoss: React.FC = () => {
  const {
    tp,
    setSelectedKeys,
    priceAccuracy,
    selectedKeys = [], //
    soketData,
    setTp,
    tl,
    setTl,
  } = useContractTradeModel() || {};
  const { t } = useTranslation();

  return (
    <div className="[&>div.px-2]:px-0 [&>div>div>h2>button]:!py-[0]">
      {/* 止盈/止损 */}
      <Accordion
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
      >
        <AccordionItem
          key="1"
          title={
            <span className="text-xs">
              <Checkbox
                isSelected={selectedKeys?.size !== 0}
                size="sm"
                color="primary"
                classNames={{
                  wrapper: cn(
                    `after:bg-[#f4f4f5] hover:!bg-[transparent] text-[#000] before:border-[#f4f4f5]`,
                  ),
                  base: 'hover:!bg-[transparent]',
                }}
                onClick={() => {
                  setSelectedKeys(
                    selectedKeys?.size === 0 ? new Set(['1']) : new Set([]),
                  );
                }}
              >
                <span className="text-xs">{t('止盈/止损')}</span>
              </Checkbox>
            </span>
          }
          indicator={<AntDesignDownOutlined className=" font-bold " />}
        >
          <div className="mb-2 flex bg-backgroundAuxiliaryColor  items-center rounded-md">
            {/* <div className="font-bold">{t('止盈')}</div> */}
            <BaseInputNumber
              value={tp}
              placeholder={t('止盈')}
              showButton={false}
              defaultValue=""
              len={priceAccuracy}
              step={1 / Math.pow(10, priceAccuracy ?? 0)}
              min={1 / Math.pow(10, priceAccuracy)}
              onChange={(value: any, isAdd: boolean) => {
                if (!tp && soketData?.a && isAdd) {
                  setTp(soketData?.a);
                } else if (Number(value) === 0 && isAdd) {
                  setTp(1 / Math.pow(10, priceAccuracy));
                } else {
                  setTp(value);
                }
              }}
              className=""
            />

            <div className="h-full flex-shrink-0 flex items-center pr-2 border-l-1 border-borderColor ml-1">
              <span className="mr-3 ml-2 font-normal">USDT</span>
            </div>

            {/* <div className="text-auxiliaryTextColor">
              {t('当最新价格触达时，将会触发市价止盈')}。
            </div> */}
          </div>

          <div className="mb-2 flex bg-backgroundAuxiliaryColor  items-center rounded-md">
            {/* <div className="font-bold">{t('止损')}</div> */}
            <BaseInputNumber
              value={tl}
              showButton={false}
              placeholder={t('止损')}
              defaultValue=""
              step={1 / Math.pow(10, priceAccuracy ?? 0)}
              min={1 / Math.pow(10, priceAccuracy)}
              len={priceAccuracy}
              onChange={(value: any, isAdd: boolean) => {
                if (!tl && soketData?.a && isAdd) {
                  setTl(soketData?.a);
                } else if (Number(value) === 0 && isAdd) {
                  setTl(1 / Math.pow(10, priceAccuracy));
                } else {
                  setTl(value);
                }
              }}
            />

            <div className="h-full flex-shrink-0 flex items-center pr-2 border-l-1 border-borderColor ml-1">
              <span className="mr-3 ml-2 font-normal">USDT</span>
            </div>

            {/* <div className="text-auxiliaryTextColor">
              {t('当最新价格触达时，将会触发市价止损')}。
            </div> */}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
