import { toast } from '@/layouts/components/ToastMessage';
import { OrderTypeEnum } from '@/pages/contract/components/ContractTypeModel';
import {
  createContractOrderApi,
  createSpecifyUrlApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { truncateNumber } from '@/utils/number';
import RenderUtil from '@/utils/RenderUtil';
import { toJsonData } from '@/utils/socket';
import { useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionTypeEnum } from '../transaction/type';
import { ContractTypeEnum, MarginModeEnum } from './type';

export const ContractTradeContext = createContext<any>(undefined);

export const useContractTradeModel = () => {
  const context = useContext(ContractTradeContext);
  if (!context) {
    throw new Error(
      'useContractTradeModel must be used within a ContractTradeProvider',
    );
  }
  return context;
};

export default () => {
  // 基础定义
  const { t } = useTranslation();
  const {
    userAmount,
    user,
    contractProfitAndLoss,
    renderFunds,
    contractAvailableFunds,
  }: any = useModel('user');
  const { getSocketRowByName }: any = useModel('socket');
  const { localContract }: any = useModel('contract');
  const { appInfo } = useModel('appInfo');
  const [enableLever, setEnableLever] = useState([]);
  const soketData = getSocketRowByName(localContract?.symbol);

  // 保证金模式
  const [tabSelected, setTabSelected] = useState(MarginModeEnum.ALL);
  const [num, setNum] = useState(0);
  const [price, setPrice] = useState(0.1);
  const [tp, setTp] = useState<any>(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [tl, setTl] = useState<any>(0);

  // 市价 限价
  const [type, setType] = useState<any>(ContractTypeEnum.MARKET);

  const [lever, setLever] = useState<any>('100');
  const VIP_OPTIONAL_LEVERAGE = useMemo(() => {
    return toJsonData(appInfo?.['VIP_OPTIONAL_LEVERAGE']) ?? [];
  }, [appInfo]);

  useEffect(() => {
    // 默认25倍杠杆，有vip等级就按等级配置走
    let maxLever = 50;
    const find = VIP_OPTIONAL_LEVERAGE?.find(
      (item: any) => Number(item?.level) === Number(user?.reservedField1),
    );
    // 查找配置中等级
    maxLever = find?.highest ?? 50;
    // 筛选出可用杠杆
    const _enableLeverList = localContract?.commisionList
      ?.sort?.((a: any, b: any) => a?.lever - b?.lever)
      ?.filter?.((item: any) => {
        return (
          (item?.lever <= maxLever && item?.isVip === true) ||
          item?.isVip === false
        );
      })
      ?.map?.((item: any, index: number) => {
        return item?.lever?.toString();
      });
    setEnableLever(_enableLeverList);
  }, [
    localContract?.commisionList,
    VIP_OPTIONAL_LEVERAGE,
    user?.reservedField1,
  ]);

  // 手续费类型 1固定
  const commissionType = useMemo(() => {
    return localContract?.commissionType ?? 0;
  }, [localContract?.commissionType]);

  // 小数长度，后台设置的精度
  const priceAccuracy = useMemo(() => {
    return localContract?.priceAccuracy ?? 6;
  }, [localContract?.priceAccuracy]);
  // 手续费
  const commission = useMemo(() => {
    return localContract?.commission;
  }, [localContract?.commission]);

  // 一手
  const firstHand = useMemo(() => {
    return localContract?.firstHand;
  }, [localContract?.firstHand]);

  // 保证金类型 1：固定，2：百分比 3：跟随杠杆）
  const earnestMoneyType = useMemo(() => {
    return localContract?.earnestMoneyType;
  }, [localContract?.earnestMoneyType]);

  // 交易精度，后台控制值，输入框
  const transactionAccuracy = useMemo(() => {
    return localContract?.transactionAccuracy ?? 6;
  }, [localContract?.transactionAccuracy]);

  // 限价的价格 或者行情价
  const memoPrice = useMemo(() => {
    return +type[0] === TransactionTypeEnum.LIMIT ? price : soketData?.a;
  }, [price, soketData?.a]);

  // 按数量下单 || 按价值下单 ||  按保证金下单

  /**
   QUANTITY_ORDER: '1', // 按数量下单
   VALUE_ORDER: '2', // 按价值下单
   MARGIN_ORDER: '3', // 按保证金下单
   */
  const [buyType, setBuyType] = useState(OrderTypeEnum.ZHANG_ORDER);

  // 保证金计算
  const amountOfConsumption = useMemo(() => {
    // 默认的num值，防止num为0时导致计算异常
    let safeNum: any = num || 0.01;
    if (Number(safeNum) === 0) {
      safeNum = 0.01;
    }

    if (buyType === OrderTypeEnum.MARGIN_ORDER) {
      // 按保证金下单
      return safeNum;
    }

    if (buyType === OrderTypeEnum.VALUE_ORDER) {
      // 按价值下单
      return safeNum / lever;
    }

    if (earnestMoneyType === 1) {
      // 固定值：保证金 * 一手 * 数量
      return localContract?.earnestMoney * firstHand * safeNum;
    } else if (earnestMoneyType === 2) {
      // 百分比：(行情价格 * 保证金 / 100) * 一手 * 数量
      return (
        ((memoPrice * localContract?.earnestMoney) / 100) * firstHand * num
      );
    } else if (earnestMoneyType === 3) {
      // 跟随杠杆：一手 * 数量 * 行情价格 / 杠杆
      return (firstHand * safeNum * memoPrice) / lever;
    }
    return 0;
  }, [
    lever,
    commissionType,
    num,
    firstHand,
    localContract?.earnestMoney,
    memoPrice,
    buyType,
  ]);

  // 手续费计算
  const premium = useMemo(() => {
    // 找到 杠杆对应的 保证金
    const find = localContract?.commisionList?.find(
      (item: any) => +item?.lever === +lever,
    );
    return (num * localContract?.earnestMoney * find?.commission) / 100;
  }, [
    commissionType,
    localContract?.earnestMoney,
    localContract?.commisionList,
    amountOfConsumption,
    commission,
    lever,
    soketData?.a,
    num,
  ]);

  // 最大资金 = 可用余额减去最大手续费
  const maxBalance = useMemo(() => {
    return contractAvailableFunds;
  }, [contractAvailableFunds]);

  const [selectedKeys, setSelectedKeys] = useState<any>(new Set([]));

  useUpdateEffect(() => {
    setSelectedKeys(new Set([]));
  }, [localContract]);

  // 合约 输入类型切换
  const typeModel = useDisclosure();

  // 是否100% 购买
  const [isBuyFull, setIsBuyFull] = useState(false);

  // 1手价钱计算
  const oneHandPrice = useMemo(() => {
    // 固定值：保证金 * 一手
    // 百分比：(行情价格 * 保证金 / 100) * 一手
    if (earnestMoneyType === 1) return amountOfConsumption * firstHand;
    return ((soketData.a * amountOfConsumption) / 100) * firstHand;
  }, [commissionType, firstHand, amountOfConsumption, soketData.a]);

  // 最大手数 == 100% 的手数
  const maxLot = useMemo(() => {
    // ÷ 0 会导致问题
    if (!contractAvailableFunds || !soketData.a) {
      return 0;
    }

    // 获取手续费比例
    const find = localContract?.commisionList?.find(
      (item: any) => +item?.lever === +lever,
    );
    if (!find) return 0;

    if (oneHandPrice === 0) {
      // 保证金额为 0 ，有问题，算不出最大手数
      return 0;
    }

    const commissionRate = find?.commission / 100;
    // 总消耗金额 = 手续费 + 真实保证金 = 真实保证金 * 手续费比例 + 真实保证金

    if (earnestMoneyType === 1) {
      // 固定值：保证金 * 一手 * 数量
      // 总消耗金额 = 保证金 * 一手 * 数量 * 手续费比例 + 保证金 * 一手 * 数量
      // 总消耗金额 = (保证金 * 一手 * 手续费比例 + 保证金 * 一手) * 数量
      // 最大购买数量 = 总消耗金额 / (保证金 * 一手 * 手续费比例 + 保证金 * 一手)
      return (
        contractAvailableFunds /
        (oneHandPrice * firstHand * commissionRate + oneHandPrice * firstHand)
      );
    } else if (earnestMoneyType === 2) {
      // 百分比：(行情价格 * 保证金 / 100) * 一手 * 数量
      // 总消耗金额 = 真实保证金 * 手续费比例 + 真实保证金
      // 总消耗金额 = ((行情价格 * 保证金 / 100) * 一手 * 数量) * 手续费比例 + (行情价格 * 保证金 / 100) * 一手 * 数量
      // 总消耗金额 = (((行情价格 * 保证金 / 100) * 一手) * 手续费比例 + (行情价格 * 保证金 / 100) * 一手) * 数量
      // 最大购买数量 = 总消耗金额 / (((行情价格 * 保证金 / 100) * 一手) * 手续费比例 + (行情价格 * 保证金 / 100) * 一手)
      return (
        contractAvailableFunds /
        (((soketData.a * oneHandPrice) / 100) * firstHand * commissionRate +
          ((soketData.a * oneHandPrice) / 100) * firstHand)
      );
    } else {
      // 跟随杠杆：一手 * 数量 * 行情价格 / 杠杆
      // 总消耗金额 = 真实保证金 * 手续费比例 + 真实保证金
      // 总消耗金额 = (一手 * 数量 * 行情价格 / 杠杆) * 手续费比例 + 一手 * 数量 * 行情价格 / 杠杆
      // 总消耗金额 = ((一手 * 行情价格 / 杠杆) * 手续费比例 + 一手 * 行情价格 / 杠杆) * 数量
      // 最大购买数量 = 总消耗金额 / ((一手 * 行情价格 / 杠杆) * 手续费比例 + 一手 * 行情价格 / 杠杆)
      return (
        contractAvailableFunds /
        (((firstHand * soketData.a) / lever) * commissionRate +
          (firstHand * soketData.a) / lever)
      );
    }
  }, [oneHandPrice, lever, soketData.a, premium, contractAvailableFunds]);

  // 判断是否有止盈止损然后折叠
  const hasTlTp = useMemo(() => {
    return selectedKeys.size !== 0;
  }, [selectedKeys]);

  const maxInputVal: any = useMemo(() => {
    if (!contractAvailableFunds || !soketData?.a) {
      return 0;
    }
    // 保证金计算
    let maxNum = maxBalance - 0.1;
    // 按张下单(最大资金/(一手的价值加每手手续费))
    // 每手的手续费=保证金*手续费比例/100
    if (buyType === OrderTypeEnum.ZHANG_ORDER) {
      const find = localContract?.commisionList?.find(
        (item: any) => +item?.lever === +lever,
      );
      if (!find) return 0;
      // 一手的保证金
      const oneHandPrice = localContract?.earnestMoney / lever;
      // 一手的手续费
      const commission = (localContract?.earnestMoney * find?.commission) / 100;
      maxNum = maxBalance / (oneHandPrice + commission);
    }
    // 按数量下单
    if (buyType === OrderTypeEnum.QUANTITY_ORDER) {
      // 最大手数 * 拉杆值 *0.01
      maxNum = maxLot;
    }
    if (buyType === OrderTypeEnum.VALUE_ORDER) {
      //  按价值下单
      maxNum = maxBalance * lever;
    }
    const maxNumByformat = RenderUtil.formatNum(maxNum, 0);
    // eslint-disable-next-line eqeqeq
    if (maxNumByformat == 0) {
      return '0';
    }
    return maxNumByformat;
  }, [
    buyType,
    maxLot,
    lever,
    maxBalance,
    transactionAccuracy,
    contractAvailableFunds,
    localContract?.earnestMoney,
  ]);

  // 数量改变拉杆百分比
  function calculateLeverFromNum(num: number | string) {
    if (!userAmount?.walletBalance || !soketData?.a) {
      return null;
    }

    let value: any;
    // 按张
    if (buyType === OrderTypeEnum.ZHANG_ORDER) {
      value = Number(num) / maxInputVal / 0.01;
    }
    // 按价值
    if (buyType === OrderTypeEnum.VALUE_ORDER) {
      value = ((Number(num) / maxBalance) * lever) / 0.01;
    }
    // 按数量
    if (buyType === OrderTypeEnum.VALUE_ORDER) {
      value = Number(num) / maxLot / 0.01;
    }

    if (Number(value) < 0) {
      value = 0;
    } else if (Number(value) > 100) {
      value = 100;
    }

    return value;
  }

  // 处理拉杆
  function handleSliderChange(value: any) {
    if (!contractAvailableFunds || !soketData?.a) {
      return;
    }
    setIsBuyFull(Number(value) === 100);

    // 保证金计算
    let maxNum: any = Number(maxInputVal) * (value * 0.01);
    if (maxNum < 0 || value === 0) {
      maxNum = 0;
    }
    if (value === 100) {
      maxNum = maxInputVal;
    }
    const maxNumByformat = RenderUtil.formatNum(maxNum, transactionAccuracy);
    // eslint-disable-next-line eqeqeq
    if (maxNumByformat == 0) {
      setNum(0);
    } else {
      setNum(maxNumByformat);
    }
    setCurrentPosition(value);
  }

  // 当 lever 改变时，重新计算最大手数
  useEffect(() => {
    if (currentPosition) {
      // handleSliderChange(currentPosition);
    }
  }, [lever, buyType]);

  useUpdateEffect(() => {
    // 市价变化到 限价 时候，直接赋值到当前价
    if (type === ContractTypeEnum.LIMIT && soketData?.a) {
      setPrice(soketData?.a);
    }
  }, [type]);

  const { reLoadOrder } = useModel('system');

  const vipModelRef = useDisclosure();
  //购买一个订单 按保证金
  const { run: onSubmitRequest, loading: submitLoading } = useReq(
    createSpecifyUrlApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        reLoadOrder();
        // onSuccess?.();
        toast.success(t('购买成功'));
      },
      onError(e: any) {
        if (e?.resCode && e.resCode.toString().startsWith('1006033')) {
          //   onClose?.();
          vipModelRef.onOpen();
        }
      },
    },
  );

  //购买一个订单 按 币对
  const { run: buySubmitRequest, loading } = useReq(createContractOrderApi, {
    loadingDefault: false,
    manual: true,
    onSuccess: () => {
      // reLoadOrder();
      //   onSuccess?.();
      toast.success(t('购买成功'));
    },
    onError(e: any) {
      if (e?.resCode && e.resCode.toString().startsWith('1006033')) {
        // onClose?.();
        vipModelRef.onOpen();
      }
    },
  });

  // 提交表单
  const onSubmit = (direction?: number) => {
    if (loading) {
      return; // 在加载的过程中不要再次请求提交了
    }

    if (Number(num) > Number(maxInputVal)) {
      const toastId = 'maxOpenToast'; // 为这个toast设置一个唯一ID
      // 检查这个toast是否已经弹出
      return (
        toast.error(t('最大可开') + ' ' + truncateNumber(maxInputVal, 0)) +
        t('张')
      );
    }
    if (Number(num) < 1) {
      toast.error(
        t('最小下单为 {{num}} 张', {
          num: 1,
        }),
      );
      return;
    }

    if (isNaN(num) || num <= 0) {
      return toast.error(t('请输入正确的数量'));
    }
    if ((+type[0] === TransactionTypeEnum.LIMIT && isNaN(price)) || price < 0) {
      return toast.error(t('请输入正确的价格'));
    }

    if (hasTlTp) {
      if (direction === 1) {
        // 做多
        if (tp !== '' && tp !== '0' && tp !== 0 && tp < soketData?.a) {
          toast.error(t('止盈价格不能低于当前价格'));
          return;
        }
        if (tl !== '' && tl !== '0' && tl !== 0 && tl > soketData?.a) {
          toast.error(t('止损价格不能高于当前价格'));
          return;
        }
      } else if (direction === 2) {
        // 做空
        if (tp !== '' && tp !== '0' && tp !== 0 && tp > soketData?.a) {
          toast.error(t('止盈价格不能高于当前价格'));
          return;
        }
        if (tl !== '' && tl !== '0' && tl !== 0 && tl < soketData?.a) {
          toast.error(t('止损价格不能低于当前价格'));
          return;
        }
      }
    }

    if (buyType === OrderTypeEnum.ZHANG_ORDER) {
      //购买一个订单 按  张 按数量下单
      buySubmitRequest({
        symbolContractId: localContract?.id,
        //数量 √
        num: parseInt(num + ''),
        //交易方向（1：买涨，2：买跌）
        direction: direction,
        earnestMode: 1,
        slNum: hasTlTp ? tl : undefined,
        slType: 4,
        spNum: hasTlTp ? tp : undefined,
        spType: 4,
        source: 2,
        localState: 0,
        symbolEarnestMoney: localContract?.earnestMoney,
        symbolEarnestMoneyType: 1,
        type: type,
        limitPrice: price,
        lever: lever,
        earnestMoney: 0,
        isFull: currentPosition === 100,
      });
    } else if (buyType === OrderTypeEnum.MARGIN_ORDER) {
      // 购买一个订单 按保证金
      onSubmitRequest({
        symbolContractId: localContract?.id,
        specifyEarnestMoney: num,
        direction: direction,
        earnestMode: tabSelected,
        slNum: hasTlTp ? tl || '' : undefined,
        slType: 4,
        spNum: hasTlTp ? tp || '' : undefined,
        spType: 4,
        source: 2,
        localState: 0,
        type: type[0],
        limitPrice: price,
        lever: lever,
        isFull: isBuyFull,
        earnestMoney: 0,
      });
    } else if (buyType === OrderTypeEnum.QUANTITY_ORDER) {
      //购买一个订单 按  按数量下单
      buySubmitRequest({
        symbolContractId: localContract?.id,
        //数量 √
        num,
        //交易方向（1：买涨，2：买跌）
        direction: direction,
        earnestMode: tabSelected,
        slNum: hasTlTp ? tl : undefined,
        slType: 4,
        spNum: hasTlTp ? tp : undefined,
        spType: 4,
        source: 2,
        localState: 0,
        type: type[0],
        limitPrice: price,
        lever: lever,
        earnestMoney: 0,
        isFull: isBuyFull,
      });
    } else if (buyType === OrderTypeEnum.VALUE_ORDER) {
      //购买一个订单 按 按价值下单
      buySubmitRequest({
        symbolContractId: localContract?.id,
        //数量 √
        num: num / soketData?.a,
        //交易方向（1：买涨，2：买跌）
        direction: direction,
        earnestMode: tabSelected,
        slNum: hasTlTp ? tl : undefined,
        slType: 4,
        spNum: hasTlTp ? tp : undefined,
        spType: 4,
        source: 2,
        localState: 0,
        type: type[0],
        limitPrice: price,
        lever: lever,
        earnestMoney: 0,
        isFull: isBuyFull,
      });
    }
  };

  return {
    lever,
    setLever,
    enableLever,
    soketData,
    priceAccuracy,
    premium,
    firstHand,
    type,
    setType,
    price,
    setPrice,
    buyType,
    maxInputVal,
    num,
    setNum,
    transactionAccuracy,
    setIsBuyFull,
    setCurrentPosition,
    calculateLeverFromNum,
    currentPosition,
    handleSliderChange,
    renderFunds,
    contractProfitAndLoss,
    selectedKeys,
    setSelectedKeys,
    tp,
    setTp,
    tl,
    setTl,
    onSubmit,
    typeModel,
    setBuyType,
    hasTlTp,
    vipModelRef,
    contractAvailableFunds,
    submitLoading,
    memoPrice,
    setTabSelected,
    tabSelected,
  };
};
