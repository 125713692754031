import { useModel } from 'umi';
import ContractOrderList from '../order/index/components/ContractOrderList';
import { Header } from './components/Header';
import { OrderBook } from './components/OrderBook';
import { TradePanel } from './components/TradePanel';
import contractModel, { ContractTradeContext } from './contractModel';

// 新版 币安 合约
export default () => {
  const modelProps = contractModel() || {};

  const { LocalChartInfo } = useModel('kline');


  return (
    <div className="text-xs">
      <ContractTradeContext.Provider value={modelProps}>
        {/* 合约 币种 头部 */}
        {/* 全仓 杠杆 */}
        <Header />

        <div className="flex ">
          <div className="flex-5">
            {/* 交易面板 */}
            <TradePanel />
          </div>
          <div className="flex-3 pr-3">
            {/* 合约 深度图 */}
            <OrderBook />
          </div>
        </div>

        {/* 合约订单 */}
        <div className="min-h-[40vh] pt-2">
          <ContractOrderList symbol={LocalChartInfo?.id} siftNode={<></>} />
        </div>
      </ContractTradeContext.Provider>
    </div>
  );
};
