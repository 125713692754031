import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import { IcSharpSignpost } from '@/assets/icons/transaction/IcSharpSignpost';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import ValueDisplay from '@/components/display/displayValueDisplay';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import FormBottomSheet from '@/components/form/formBottomSheet';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import ContractLever from '@/pages/contract/components/ContractLever';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { formatCountdown } from '@/utils';
import { ScrollShadow } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useCountDown, useInViewport, useMount, useUnmount } from 'ahooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContractTradeModel } from '../contractModel';
import { MarginModeEnum } from '../type';

// 币安版本合约 头部
export const Header: React.FC = () => {
  const { t } = useTranslation();

  const { lever, setLever, enableLever, tabSelected, setTabSelected }: any =
  useContractTradeModel() || {};

  const marginOption: any = [
    {
      text: t('全仓'),
      value: MarginModeEnum.ALL,
    },
    // {
    //   text: t('逐仓'),
    //   value: MarginModeEnum.ISOLATED,
    // },
  ];

  const typeText = useMemo(() => {
    return marginOption.find(
      (item: { value: any }) => Number(item.value) === Number(tabSelected),
    )?.text;
  }, [marginOption, tabSelected]);

  const { toChart, LocalChartInfo } = useModel('kline');
  const { getSocketRowByName, fundingRate } = useModel('socket');
  const socketData = getSocketRowByName(LocalChartInfo?.symbol);

  const {setLocalChoiceContractSocket} = useModel("system");


  // 使用 targetDate 来管理倒计时
  const [targetDate, setTargetDate] = useState<number | undefined>();

  // 使用 useCountDown
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      console.log('倒计时结束');
    },
  });

  // 监听 fundingRate 的变化并更新 targetDate
  useEffect(() => {
    if (fundingRate?.nextPeriodTimestamp) {
      setTargetDate(fundingRate.nextPeriodTimestamp); // 将目标时间设置为服务器返回的时间戳
    }
  }, [fundingRate]);

  const { openPrompt } = useGlobalPrompt();
  const ref = useRef<any>()
  const [inViewport] = useInViewport(ref);

  const {
    data: userAgreement = {} as any,
    runAsync,
    loading,
  } = useReq(
    () =>
      getPrivacyAgreementApi({
        type: 'FUNDING_RATE',
        noMsg: true,
      }),
    {
      manual: true,
      cacheKey: 'UserAgreement_' + 'FUNDING_RATE',
    },
  );
  useEffect(() => {
    if(inViewport || JSON.stringify(userAgreement) === "{}" || userAgreement?.code === "Response_Error"){
      runAsync()
    }
  }, [inViewport]);


  // 设置 游览器标签 title
  useEffect(()=>{
    // 合约
    if(LocalChartInfo&&location.pathname===PageEnum.CONTRACT)
      setLocalChoiceContractSocket(LocalChartInfo)
    if(LocalChartInfo&&location.pathname===PageEnum.HOME)
        setLocalChoiceContractSocket(null)
  },[LocalChartInfo,location.pathname])

  // 取消订阅
  useUnmount(()=>{
    setLocalChoiceContractSocket(null)
  })
  return (
    <div className="px-4 pt-4" ref={ref}>
      <div className="items-center">
        <div className="h-[20px] flex justify-between text-base">
          <div
            onClick={() => {
              history.push(
                PageEnum.SEARCH + '?type=' + GetCoinListTypeEnum.CONTRACT,
              );
            }}
            className="text-titleColor font-bold flex items-center"
          >
            <span className="flex items-center text-[18px]">
              <span> {LocalChartInfo?.alias ?? LocalChartInfo?.name}</span>
              <span className="h-[18px] leading-[18px] ml-1 bg-backgroundAuxiliaryColor text-[10px] px-1  font-normal rounded-[4px]">{t('永续')}</span>
            </span>
            <div className=" flex items-center">
              <TeenyiconsDownSolid width="0.5em" height="2em" className="ml-2" />
            </div>
          </div>
          <IcSharpSignpost
            width="1.5em"
            height="1.5em"
            className="text-auxiliaryTextColor"
            onClick={() => toChart(ChartTypeEnum.CONTRACT)}
          />
        </div>
        <div className="text-xs text-green-500 ">
          <ValueDisplay
            value={socketData?.pe}
            ratioValue={socketData?.pe}
            isArrow
            ratio
            fontSize={13}
            justify="justify-start"
          />
        </div>
      </div>
      <div className="flex items-start space-x-2 pt-[6px]">
        {/* 全仓 */}
        <FormBottomSheet
          onChange={setTabSelected as any}
          options={marginOption}
          value={tabSelected}
        >
          <div className="px-3 py-1 bg-gray-700 rounded-[5px] border-1  border-borderColor">
            {typeText}
          </div>
        </FormBottomSheet>

        {/* 杠杆 */}
        <ContractLever
          lever={lever}
          changeLever={setLever}
          enableLever={enableLever}
        />

        {/* 资金 费率 */}
        <div className="flex flex-1 flex-col items-end justify-end">
          <div
            onClick={() => {
              openPrompt({
                showCancel: false,
                title: t('资金费率'),
                content: (
                  <ScrollShadow
                    hideScrollBar
                    className="max-h-[50vh] sm:h-[40vh]"
                  >
                    {/*  没有数据加载loading */}
                    {!userAgreement?.content && loading && (
                      <div className="flex justify-center">
                        <SvgSpinnersBarsScale className="text-2xl text-primary" />
                      </div>
                    )}

                    <div className="my-2 max-h-[50vh] text-foreground">
                      <FeaturesRenderText data={userAgreement} />
                    </div>
                  </ScrollShadow>
                ),
              });
            }}
            className=" text-auxiliaryTextColor text-[10px] border-b-1 border-dashed"
          >
            {t('资金费率')} / {t('倒计时')}
          </div>

          <div className=" text-titleColor pt-[2px]">
            {fundingRate?.fundingRate ?? 0}% / {formatCountdown(countdown)}
          </div>
        </div>
      </div>
    </div>
  );
};
