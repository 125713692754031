import FeaturesLever from '@/components/features/featuresLever';
import { useTranslation } from 'react-i18next';

/**
 * 合约 选 杠杆的抽屉组件
 */
export default ({ lever, changeLever, enableLever = [] }: IProps) => {
  const { t } = useTranslation();

  const max = Math.max(...enableLever) ?? 100;

  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="border-1 border-borderColor rounded-[5px] px-5 py-1  flex items-center">
          <FeaturesLever
            max={max}
            min={1}
            enableLever={enableLever}
            marks={enableLever?.map((item: any) => {
              return {
                value: item,
                label: item + 'x',
              };
            })}
            changeLever={(value) => {
              changeLever(value + '', 'lever');
            }}
            value={lever}
            bottomNode={
              <>
                <div className="text-auxiliaryTextColor mt-12 flex items-center text-sm">
                  {t('当前杠杆倍数最高可开')} :{max}X
                </div>
                <div className="text-auxiliaryTextColor mt-1 flex items-center"></div>
              </>
            }
          >
            <span>{lever}x</span>
          </FeaturesLever>
        </div>
      </div>
    </div>
  );
};

interface IProps {
  lever: any;
  changeLever: any;
  enableLever: any;
}
