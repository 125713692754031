import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseModal from '@/components/base/baseModal';
import { Button, useDisclosure } from '@nextui-org/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from './Slider';

export default function FeaturesLever({
  enableLever,
  children,
  bottomNode,
  max = 100,
  changeLever,
  min = 1,
  marks,
  step = 1,
  value,
  ...props
}: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 如果合法就默认值显示value，否则 就是最小值
  const { t } = useTranslation();
  const { title = t('调整杠杆') } = props;

  useEffect(() => {
    if (!isOpen) {
      // document.body.style.overflow="hidden"
    }
  }, [isOpen]);

  return (
    <>
      <>
        <div onClick={onOpen}>{children}</div>
        <BaseModal
          isOpen={isOpen}
          onClose={onClose}
          title={title}
          isShowCloseButton={true}
        >
          <div className="mb-9  px-4 py-4">
            <BaseInputNumber
              showButton={false}
              value={value}
              step={1}
              len={0}
              disabled
              onChange={(laver: any) => {
                changeLever(laver);
              }}
              max={max}
              min={min}
              classNames={{ input: '!text-[18px]' }}
            />

            <div className="mt-6">
              <Slider
              classNames={{
                base:"h-[2px]"
              }}
                unit={'x'}
                values={enableLever}
                value={value}
                onChange={(value: any) => {
                  changeLever(value);
                }}
              />
            </div>

            {bottomNode}
          </div>
          <div className="px-4 mb-2">
            <Button
              className="mainColorButton mb-2 my-2 !py-1"
              onClick={() => {
                onClose();
              }}
            >
              {t('确认')}
            </Button>
          </div>
        </BaseModal>
      </>
    </>
  );
}

interface IProps {
  children: React.ReactNode;
  title?: string;
  step?: number;
  bottomNode?: React.ReactNode;
  changeLever: (data: any) => void;
  value: number;
  marks: { label: string; value: number }[];
  max?: number;
  min: number;
  enableLever?: any;
}
