import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseSlideRange from '@/components/base/baseSlideRange';
import FormBottomSheet from '@/components/form/formBottomSheet';
import { OrderTypeEnum } from '@/pages/contract/components/ContractTypeModel';
import RenderUtil from '@/utils/RenderUtil';
import { history, useModel } from '@umijs/max';
import React, { SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {  useContractTradeModel } from '../contractModel';
import {UnitPriceLimit} from "@/assets/icons/contractTrade/UnitPriceLimit"
import {MarketOrder} from "@/assets/icons/contractTrade/MarketOrder"
import { ContractTypeEnum } from '../type';
import AddMoneyEntranceButtonModal from '@/pages/transaction/components/AddMoneyEntranceButtonModal';
import { AddAmount } from '@/assets/icons/comm/AddAmount';
import { MingcuteTransferFill } from '@/assets/icons/funds/MingcuteTransferFill';
import TransferEntranceButtonModal from '@/pages/transaction/components/TransferEntranceButtonModal';
import { TransferIcon } from '@/assets/icons/comm/TransferIcon';
import PageEnum from '@/enums/pageEnum';
// 合约 交易面板 表单主体
export const TradePanelForm: React.FC = () => {
  const {
    maxInputVal, //最大可以开 张数
    type, // ContractTypeEnum 合约 限制价 | 市价
    setType, //设置合约类型
    price, //限价价格
    setPrice, //设置限价价格
    priceAccuracy, // 行情精度
    buyType, // OrderTypeEnum 购买类型 张 | 数量 | 保证金 | 币本位
    num, //购买数量
    setIsBuyFull, //是否全部购买
    setCurrentPosition, // 设置拉杆多少
    setNum, // 购买数量
    calculateLeverFromNum, // 数量改变拉杆百分比
    currentPosition, // 拉杆值
    handleSliderChange, // 处理
    contractAvailableFunds, //可用余额
    lever, // 当前杠杆
  } = useContractTradeModel() || {};
  const { t } = useTranslation();

  const { localContract } = useModel('contract');

  const priceTypeOption: any = [
    {
      text: t('限价单'),
      value: ContractTypeEnum.LIMIT,
      remark:t("以指定或更优价格成交"),
      Icon:UnitPriceLimit,
    },
    {
      text: t('市价单'),
      value: ContractTypeEnum.MARKET,
      remark:t("以当前价格快速成交"),
      Icon:MarketOrder
    },
  ];

  // 市价 限价 文案
  const priceTypeText = useMemo(() => {
    return priceTypeOption.find(
      (item: { value: any }) => Number(item.value) === Number(type),
    )?.text;
  }, [priceTypeOption, type]);

  // console.log(contractAvailableFunds, 'contractAvailableFunds');

  return (
    <>
      <div>
        <div className="flex  justify-between pb-2">
          <div className=" text-auxiliaryTextColor">{t('可用')}</div>
          <div className="flex items-center gap-1">
            <div className=" font-bold ml-2">
              {RenderUtil.FormatAmount(contractAvailableFunds, 2)}
              {' ' + localContract?.coinAlias}
            </div>
            <TransferIcon onClick={()=>{
              history.push(PageEnum.TRANSFER)
            }}/>
          </div>
        </div>

        {/* 限价 选择 */}
        <FormBottomSheet
          value={type}
          onChange={setType as any}
          options={priceTypeOption}
        >
          <div className="grayButton w-full  !px-2 flex justify-between items-center h-[28px] text-[14px] select-none">
          <span>{/* {占位} 不能删除 */}</span>
            <span className="exclamationMark text-titleColor text-[14px]">
              {priceTypeText}
            </span>
            <TeenyiconsDownSolid
              width="0.5em"
              height="2em"
              className="text-auxiliaryTextColor"
            />
          </div>
        </FormBottomSheet>

        {type === ContractTypeEnum.LIMIT && (
          <div className=" mt-2">

            <BaseInputNumber
              value={price}
              defaultValue=""
              title={t('价格')}

              min={0}
              step={1 / Math.pow(10, priceAccuracy ?? 0)}
              len={priceAccuracy}
              onChange={(value: SetStateAction<number>) => {
                setPrice(value);
              }}
            />
          </div>
        )}
      </div>
      <div className="pb-0 mt-2">
        <div className="relative bg-backgroundAuxiliaryColor  rounded-md flex items-center">
          <BaseInputNumber
            showButton={false}
            value={num}
            defaultValue=""
            // showTip
            // usdValue={RenderUtil.FormatAmount(num * 1000, 2)}
            step={1}
            len={0}
            min={0}
            max={maxInputVal as any}
            placeholder={t('数量')}
            onChange={(value: any, isAdd: any) => {
              if (Number(maxInputVal) >= Number(value)) {
                setIsBuyFull(true);
              } else if (value) {
                // 只要输入流数量，则默认不要全部购买
                setIsBuyFull(false);
              }
              let _num = value;
              if (Number(value) === 0 && isAdd) {
                _num = 1 / Math.pow(10, 1);
              }
              setCurrentPosition(calculateLeverFromNum(_num)!);
              setNum(_num);
            }}
            className="flex-1"
          />

          <div
            onClick={() => {
              // 这一套ui 只要数量 下单，禁用选模式
              // typeModel.onOpen();
            }}
            className="h-full flex-shrink-0 flex items-center pr-2 border-l-1 border-borderColor ml-1"
          >
            {buyType === OrderTypeEnum.ZHANG_ORDER && (
              <span className=" ">{t('张')}</span>
            )}
            {buyType !== OrderTypeEnum.ZHANG_ORDER && (
              <span className=" ">
                {buyType === OrderTypeEnum.QUANTITY_ORDER
                  ? localContract?.baseSymbol ?? localContract?.baseAlias
                  : localContract?.baseAlias ?? localContract?.coinAlias ?? ''}
              </span>
            )}
            {/* 选下单模式 */}
            {/* <AntDesignDownOutlined className="text-right font-bold text-md" /> */}
          </div>
        </div>

        {/* 仿币安拉杆 */}
        <div className="pt-[8px] pb-[4px] px-2">
          <BaseSlideRange
            values={[1, 25, 50, 75, 100]}
            step={1}
            value={currentPosition}
            onChange={handleSliderChange}
          />
        </div>
      </div>
    </>
  );
};
