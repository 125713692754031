import { OrderTypeEnum } from '@/pages/contract/components/ContractTypeModel';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { useContractTradeModel } from '../contractModel';
import { TradingDirectionEnum } from '../type';
import { Rise } from '@/assets/icons/comm/Rise';
import { Drop } from '@/assets/icons/comm/Drop';

// 合约 下单按钮
export const BuyButton = () => {
  const {
    num,
    lever,
    buyType,
    soketData,
    maxInputVal,
    priceAccuracy,
    onSubmit,
  } = useContractTradeModel() || {};

  const { localContract } = useModel('contract');
  const { t } = useTranslation();
  const { currentUpBgClass, currentDownBgClass } = useModel('system');

  // 计算保证金
  const calculateMargin = (direction: TradingDirectionEnum) => {
    let result = '';
    if ([OrderTypeEnum.ZHANG_ORDER].includes(buyType)) {
      result =
        RenderUtil.FormatAmount(
          (num * localContract?.earnestMoney) / lever,
          2,
        ) + ' ';
    } else if ([OrderTypeEnum.QUANTITY_ORDER].includes(buyType)) {
      result = RenderUtil.FormatAmount((num * soketData?.a) / lever, 2) + ' ';
    } else if (buyType === OrderTypeEnum.MARGIN_ORDER) {
      result =
        RenderUtil.FormatAmount((num * lever) / soketData?.a, priceAccuracy) +
        ' ';
    } else if ([OrderTypeEnum.VALUE_ORDER].includes(buyType)) {
      result = RenderUtil.FormatAmount(num / soketData?.a, priceAccuracy) + ' ';
    }

    const suffix = [
      OrderTypeEnum.QUANTITY_ORDER,
      OrderTypeEnum.ZHANG_ORDER,
    ].includes(buyType)
      ? localContract?.coinAlias ?? ''
      : localContract?.baseSymbol ?? '';

    if (Number(result) === 0) {
      return '--';
    }

    return result + suffix;
  };

  // 公共渲染部分
  const renderPanel = (
    direction: TradingDirectionEnum,
    label: string,
    bgClass: string,
    buttonText: string,
  ) => (
    <div className="flex flex-col items-center space-y-1">
      <div className="w-full">
        <div className="flex justify-between">
          <span className="text-auxiliaryTextColor">{label}</span>
          <span>
            {maxInputVal} {t('张')}
          </span>
        </div>
        <div className="flex justify-between pt-2">
          <span className="text-auxiliaryTextColor">{t('保证金')}</span>
          <span>{calculateMargin(direction)}</span>
        </div>
      </div>
      <Button
        className={`${bgClass} w-full my-2 text-[#fff] rounded-md`}
        onClick={() => onSubmit(direction)}
      >
        {direction === TradingDirectionEnum.LONG ? <Rise className="text-[#fff] mr-1" /> : <Drop className="text-[#fff] mr-1" />}
        {buttonText}
      </Button>
    </div>
  );

  return (
    <div className="flex flex-col space-y-4 text-xxs">
      {renderPanel(
        TradingDirectionEnum.LONG,
        t('可开多'),
        currentUpBgClass,
        t('买涨'),
      )}
      {renderPanel(
        TradingDirectionEnum.SHORT,
        t('可开空'),
        currentDownBgClass,
        t('买跌'),
      )}
    </div>
  );
};
