import { IconParkSolidCorrect } from '@/assets/icons/transaction/IconParkSolidCorrect';
import BaseDrawer from '@/components/base/baseModal';
import { useModel } from '@@/exports';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from '@nextui-org/react';
import { ReactNode } from 'react';

// 底部弹出单选
// pc样式兼容
export default function FormBottomSheet({
  value,
  options,
  onChange,
  children,
}: IProps) {
  //  useDisclosure Hook
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pickValue = (value: string) => {
    onChange(value);
    onClose();
  };

  // 检测是否为移动端
  const { isMobile } = useModel('system');

  return (
    <>
      {/* 渲染移动端 */}
      {isMobile && (
        <>
          <span onClick={onOpen}>{children}</span>
          <BaseDrawer isOpen={isOpen} hideCloseButton onClose={onClose}>
          <div className="mb-6 mt-4 flex flex-wrap gap-y-2 px-4 ">
              {options.map((item:any) => {
                return (
                  <div
                    key={item?.value}
                    className="w-full flex justify-between items-center p-4  w-full text-backContrastColor border-[1px] border-solid rounded-[8px] border-borderColor"
                    onClick={() => pickValue(item?.value)}
                  >
                    <div className='max-w-[90%] w-fit flex'>
                      {item?.Icon&&<item.Icon className="w-[32px] h-[32px] mr-3 text-currentColor"/>}
                      <div className='w-full flex flex-wrap !font-[400]'>
                        <span className='w-full text-[16px] leading-[20px] text-titleColor'>{item?.text}</span>
                        <span className='w-full text-[12px] leading-[16px] text-auxiliaryTextColor'>{item?.remark}</span>
                      </div>
                    </div>
                    
                    {item?.value === value && <IconParkSolidCorrect />}
                  </div>
                );
              })}
            </div>
          </BaseDrawer>
        </>
      )}

      {/* 渲染PC端 */}
      {!isMobile && (
        <Dropdown classNames={{ content: 'bg-background' }}>
          <DropdownTrigger>{children}</DropdownTrigger>
          <DropdownMenu
            selectionMode="single"
            selectedKeys={[value + '']}
            className="text-auxiliaryTextColor"
          >
            {(options as any)?.map((item: any) => {
              return (
                <DropdownItem
                  onClick={() => pickValue(item.value + '')}
                  key={item?.value + ''}
                >
                  {item.text}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
}

// 定义组件接收的props接口
interface IProps {
  options: {
    text: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  children: ReactNode;
  value: string;
}
